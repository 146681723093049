import React from 'react'

import { FloatingBar } from 'components/FloatingBar'
import { BottomBar } from 'components/BottomBar'
import { Header } from 'components/Header'
import { BlockCTA } from 'components/Header/components/BlockCTA'

import { Blocks } from 'components/Blocks'
import Seo from 'components/seo'

const Homepage = ({ pageContext: { pageData } }) => {
  return (
    <>
      <Seo title={pageData.title} meta={pageData.seo} />

      <FloatingBar />
      <BottomBar />
      <Header
        h1={pageData.header.h1}
        h2={pageData.header.h2}
        theses={pageData.header.theses}
        button={pageData.header.button}
        rightBlock={<BlockCTA />}
        breadcrumbs={false}
      />

      <Blocks items={pageData.blocks} />
    </>
  )
}

export default Homepage

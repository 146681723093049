import React, { useEffect } from "react";

// Components
import { Button } from '../Button';

// Styles
import * as cls from './floatingbar.module.scss';

export function FloatingBar() {

  useEffect(() => {
    window.addEventListener('scroll', stickyFloatingBar);
    return () => {
      window.removeEventListener('scroll', stickyFloatingBar);
    }
  });

  const stickyFloatingBar = () => {
    const floatingBar = document.querySelector('.floating_bar');
    const scrollTop = window.scrollY;
    scrollTop > 500 ? floatingBar.classList.add(`${cls.is_sticky}`) : floatingBar.classList.remove(`${cls.is_sticky}`);
  };

  return (
    <div className={`${cls.floating_bar} floating_bar`}>
      <div className='container'>
        <div className={cls.floating_bar_inner}>
          <h4>(Ver)-Erben einfach gemacht</h4>
          <div className={cls.floating_bar_links}>
            <Button text='Testament erstellen' href='/' />
            <Button text='Erbanteil verkaufen' href='/' />
            <Button text='Anwalt mandatieren' href='/' />
          </div>
        </div>
      </div>
    </div>
  );
}
